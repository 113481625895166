@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

* {
  font-family: 'Pretendard';
}

input {
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 8px;
  caret-color: #3182f7;
}
input:focus {
  /* border: none; */
  outline: #3182f7;
  border-radius: 10px;
  padding: 8px;
  caret-color: #3182f7;
}

.money-input {
  outline: #3182f7;
  font-size: 30px;
  font-weight: bold;
  height: 50px;
  padding: 0px;
  margin: 0px;
  border-radius: 0px;
  /* font-size: larger; */
}
.money-input:focus {
  outline: none;
  border-radius: 0px;
  padding: 0px;
  caret-color: #3182f7;
}
.money-input::placeholder {
  font-weight: bold;
  font-size: 30px;
  color: #d0d6dc;
}

button {
  border: none;
  outline: none;
  border-radius: 10px;
  color: white;
  background-color: #3182f7;
  padding: 8px;
  display: inline-block;
  height: 50px;
  font-size: large;
}
button:disabled {
  background-color: #d1d5d6;
}

.secondary-button {
  color: #4f5865;
  background-color: #f3f4f6;
}

/* button:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
} */

::-moz-selection {
  /* Code for Firefox */
  color: black;
  background: #aee1f5;
}

::selection {
  color: black;
  background: #aee1f5;
}

.grayscale {
  filter: grayscale(1);
  opacity: 50%;
}

.fd {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.fb {
  width: 100%;
  margin: 20px;
}
